* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-size: 13px;
  color: #3A3A3A;
}

body.freeze {
  overflow-y: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6, p {
  color: inherit;
}

h1 {
  font-size: 3.5rem;
  /* font-weight: 700; */
}

h2 {
  font-size: 3rem;
  line-height: 3.5rem;
  /* font-weight: 500; */
}

h3 {
  font-size: 2.5rem;
  line-height: 3rem;
  /* font-weight: 300; */
}

h4 {
  font-size: 2rem;
  line-height: 2.5rem;
  letter-spacing: 0.025em;
}

h5 {
  font-size: 1.5rem;
  line-height: 1.75rem;
  letter-spacing: 0.025em;
}

h6 {
  font-size: 1.25rem;
  line-height: 1.5rem;
  letter-spacing: 0.025rem;
}

p {
  font-size: 1.15rem;
  line-height: 1.5rem;
}

small {
  font-size: 0.85rem;
}

button {
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  padding: 16px;
}

a,
span,
strong,
em,
b,
i {
  font-size: inherit;
  color: inherit;
}

a,
span,
em {
  font-weight: inherit;
}

strong,
b {
  font-weight: 700;
}

a:hover {
  text-decoration: underline;
}

input,
textarea {
  font-size: 0.85rem;
}

.hyphenate {
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}

time {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
}

ul, ol {
  list-style-position: inside;
}